import React from 'react';
import { graphql } from 'gatsby';
import CustomPageWrapper from '../components/CustomPageWrapper/CustomPageWrapper';
import LifeAtSmarttie from '../sections/life-at-smarttie';
import Helmet from '../components/Helmet';

const careers = () => {
  const title = 'Join Smarttie: Boost Your Career';

  const description =
    'Explore opportunities at Smarttie and transform the future with us. Discover how your talent can contribute to innovation and global impact.';

  const banner = true;

  const url = 'https://www.smarttie.ca/careers/';

  const data = {
    title,
    description,
    banner,
    url,
  };

  return (
    <>
      <Helmet data={data} />

      <CustomPageWrapper withNavbar={true}>
        <LifeAtSmarttie />
      </CustomPageWrapper>
    </>
  );
};

export default careers;

// export const Head = () => {
//   const title = 'Join Smarttie: Boost Your Career';

//   const description =
//     'Explore opportunities at Smarttie and transform the future with us. Discover how your talent can contribute to innovation and global impact.';

//   const banner = true;

//   const url = 'https://www.smarttie.ca/careers/';

//   const data = {
//     title,
//     description,
//     banner,
//     url,
//   };

//   return (
//     <>
//       <Helmet data={data} />
//     </>
//   );
// };

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
